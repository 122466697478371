import { RowBrokers } from '@pretto/website/src/templates/home/rows/rowBrokers/components/RowBrokers'

export type RowBrokersProps = {
  areTextsDisplayed: boolean
  ctaLabel: string
  ctaUrl: string
  description: string
  images: { src: string }[]
  isColored: boolean
  isCtaDisplayed: boolean
  suptitle: string
  texts: { content: string }[]
  title: string
}

export const RowBrokersContainer: React.FC<RowBrokersProps> = ({
  images,

  texts,
  ...props
}) => {
  const rowBrokersProps = {
    images: images.map(({ src }) => src) || [],
    texts: texts.map(({ content }) => content) || [],
    ...props,
  }

  return <RowBrokers {...rowBrokersProps} />
}
