import { breakpoints, g } from '@pretto/bricks/components/layout'

import { transition } from '@pretto/zen/reveal/lib/transitionCss'

import { CheckBold } from '@pretto/picto'

import styled, { css } from 'styled-components'

interface BlocProps {
  children: React.ReactNode
  isActive: boolean
  isPassed: boolean
}

export const Bloc: React.FC<BlocProps> = ({ children, isActive, isPassed, ...props }) => (
  <Container {...props} $isActive={isActive} $isPassed={isPassed}>
    <CheckContainer $isActive={isActive} $isPassed={isPassed}>
      <Check />
    </CheckContainer>

    <Description>{children}</Description>
  </Container>
)

interface ActiveProps {
  $isActive: boolean
  $isPassed: boolean
}

const Container = styled.div<ActiveProps>`
  display: grid;
  grid-template-columns: ${g(6)} auto;
  opacity: 0;
  ${transition({ propertyName: 'color' }, { propertyName: 'opacity' }, { propertyName: 'grid-template-rows' })};

  ${({ $isActive }) =>
    $isActive &&
    css`
      opacity: 1;
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-template-columns: ${g(7)} auto;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    color: ${({ theme }) => theme.colors.neutral3};
    opacity: 1;
    grid-template-columns: auto;
    grid-template-rows: 0 auto;

    ${({ $isActive, $isPassed }) =>
      ($isActive || $isPassed) &&
      css`
        color: inherit;
        grid-template-rows: ${g(6)} auto;
      `}
  }
`

const CheckContainer = styled.div<ActiveProps>`
  width: ${g(4)};
  height: ${g(4)};
  flex-shrink: 0;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral5};
  opacity: 0;
  ${transition({ propertyName: 'opacity' })};

  ${({ $isActive, $isPassed }) =>
    ($isActive || $isPassed) &&
    css`
      opacity: 1;
    `}
`

const Check = styled(CheckBold)`
  height: ${g(1, 4)};
`

const Description = styled.p`
  ${({ theme }) => theme.typos.body4};
  margin-top: ${g(1 / 2)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-top: 0;
  }
`
