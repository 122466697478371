import { breakpoints, g, numeralBreakpoints } from '@pretto/bricks/components/layout'
import ImageComponent from '@pretto/bricks/website/shared/components/Image'

import { transition } from '@pretto/zen/reveal/lib/transitionCss'

import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useDebounce } from 'use-debounce'

export interface ImagesProps {
  currentIndex: number
  images: string[]
  onPaginationChange: (index: number) => void
}

export const Images: React.FC<ImagesProps & React.HTMLAttributes<HTMLDivElement>> = ({
  currentIndex,
  images,
  onPaginationChange,
  ...props
}) => {
  const [scrollCurrentIndex, setScrollCurrentIndex] = useState(0)

  const [debouncedScrollCurrentIndex] = useDebounce(scrollCurrentIndex, 500)

  useEffect(() => {
    onPaginationChange(debouncedScrollCurrentIndex)
  }, [debouncedScrollCurrentIndex])

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const offsetWidth = event.currentTarget.offsetWidth
    const scrollLeft = event.currentTarget.scrollLeft
    const currentIndex = Math.round(scrollLeft / offsetWidth)

    setScrollCurrentIndex(currentIndex)
  }

  return (
    <Container {...props} $currentIndex={currentIndex} onScroll={handleScroll}>
      <Image1>
        <Image $isVisible={currentIndex === 0} alt="" path={images[0]} />
      </Image1>

      <Image2>
        <Image $isVisible={currentIndex === 1} alt="" path={images[1]} />
      </Image2>

      <Image3>
        <Image $isVisible={currentIndex === 2} alt="" path={images[2]} />
      </Image3>
    </Container>
  )
}

interface ContainerProps {
  $currentIndex: number
}

const Container = styled.div<ContainerProps>`
  --gap: ${g(2)};

  --minColumnHeight: ${g(31)};
  --minColumnWidth: calc(100% - ${g(10)});

  --maxColumnHeight: var(--minColumnHeight);
  --maxColumnWidth: var(--minColumnWidth);

  display: grid;
  grid-gap: var(--gap);
  grid-template-columns: auto repeat(3, var(--maxColumnWidth)) auto;
  grid-template-rows: var(--maxColumnHeight);
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-padding-left: ${g(2, 4)};
  scroll-padding-right: ${g(2, 4)};
  scroll-snap-type: x mandatory;
  ${transition(
    { duration: '800ms', propertyName: 'grid-template-columns' },
    { duration: '800ms', propertyName: 'grid-template-rows' }
  )};
  will-change: scroll-position;

  &:before,
  &:after {
    content: '';
    min-width: ${g(1 / 2)};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    --minColumnHeight: ${g(46)};
    --minColumnWidth: ${g(9)};

    --maxColumnHeight: var(--minColumnHeight);
    --maxColumnWidth: calc(100% - (var(--minColumnWidth) * 2 + var(--gap) * 2));

    &:before,
    &:after {
      content: normal;
    }

    overflow-x: visible;

    ${({ $currentIndex }) => {
      if ($currentIndex === 1) {
        return css`
          grid-template-columns: var(--minColumnWidth) var(--maxColumnWidth) var(--minColumnWidth);
        `
      }

      if ($currentIndex === 2) {
        return css`
          grid-template-columns: var(--minColumnWidth) var(--minColumnWidth) var(--maxColumnWidth);
        `
      }

      return css`
        grid-template-columns: var(--maxColumnWidth) var(--minColumnWidth) var(--minColumnWidth);
      `
    }}
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    --minColumnHeight: ${g(8)};
    --minColumnWidth: ${g(8)};

    --maxColumnHeight: calc(${g(56)} - var(--minColumnHeight) - var(--gap));
    --maxColumnWidth: calc(100% - (var(--minColumnWidth) + var(--gap)));

    ${({ $currentIndex }) => {
      if ($currentIndex === 1) {
        return css`
          grid-template-columns: var(--minColumnWidth) var(--maxColumnWidth);
          grid-template-rows: var(--maxColumnHeight) var(--minColumnHeight);
        `
      }

      if ($currentIndex === 2) {
        return css`
          grid-template-columns: var(--maxColumnWidth) var(--minColumnWidth);
          grid-template-rows: var(--minColumnHeight) var(--maxColumnHeight);
        `
      }

      return css`
        grid-template-columns: var(--minColumnWidth) var(--maxColumnWidth);
        grid-template-rows: var(--minColumnHeight) var(--maxColumnHeight);
      `
    }}
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    --minColumnHeight: ${g(10)};
    --minColumnWidth: ${g(10)};
  }
`

interface ImageProps {
  $isVisible: boolean
}

const Image = styled(ImageComponent).attrs({
  size: `(min-width: ${breakpoints.laptop}) 50vw, 100vw`,
  srcSetBreakpoints: [
    numeralBreakpoints.mobileS,
    numeralBreakpoints.mobileM,
    numeralBreakpoints.mobileL,
    numeralBreakpoints.tablet,
    numeralBreakpoints.laptop,
    numeralBreakpoints.desktop,
  ],
})<ImageProps>`
  display: block;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  ${transition({ propertyName: 'opacity' })};
  width: 100%;

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      opacity: 1;
    `}
`

const BaseImage = styled.div`
  border-radius: ${g(1)};
  overflow: hidden;
`

const Image1 = styled(BaseImage)`
  background-color: ${({ theme }) => theme.colors.accent3};
  scroll-snap-align: start;

  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-column: 2;
    grid-row: 2;
  }
`

const Image2 = styled(BaseImage)`
  background-color: ${({ theme }) => theme.colors.neutral4};
  scroll-snap-align: center;

  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-column: 2;
    grid-row: 1;
  }
`

const Image3 = styled(BaseImage)`
  background-color: ${({ theme }) => theme.colors.primary1};
  scroll-snap-align: end;

  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-column: 1;
    grid-row: span 2;
  }
`
