import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import { useState } from 'react'
import styled from 'styled-components'

import { Banner } from './components/Banner/Banner'
import { Rtb as RtbComponent } from './components/Rtb/Rtb'

export type RowBrokersProps = {
  areTextsDisplayed: boolean
  ctaLabel: string
  ctaUrl: string
  description: string
  images: string[]
  isColored: boolean
  isCtaDisplayed: boolean
  suptitle: string
  texts: string[]
  title: string
}

export const RowBrokers: React.FC<RowBrokersProps> = ({
  areTextsDisplayed,
  ctaLabel,
  ctaUrl,
  description,
  images,
  isCtaDisplayed,
  suptitle,
  texts,
  title,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleEnd = (index: number) => {
    setCurrentIndex((index + 1) % 3)
  }

  const handlePaginationChange = (index: number) => {
    setCurrentIndex(index)
  }

  return (
    <Bloc>
      <Banner
        ctaLabel={ctaLabel}
        ctaUrl={ctaUrl}
        currentIndex={currentIndex}
        description={description}
        images={images}
        isCtaDisplayed={isCtaDisplayed}
        onPaginationChange={handlePaginationChange}
        suptitle={suptitle}
        title={title}
      />

      {areTextsDisplayed && texts && <Rtb currentIndex={currentIndex} duration={5} onEnd={handleEnd} rtb={texts} />}
    </Bloc>
  )
}

const Bloc = styled.section`
  ${grid()};
  padding: ${g(6)} 0 ${g(8)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(8)} 0 ${g(9)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(12)} 0 ${g(13)};
  }
`

const Rtb = styled(RtbComponent)`
  ${column([2, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])}
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 10])}
  }
`
