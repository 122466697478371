import { breakpoints, g } from '@pretto/bricks/components/layout'

import { transition } from '@pretto/zen/reveal/lib/transitionCss'

import styled, { css, keyframes } from 'styled-components'

import { Bloc as BlocComponent } from './components/Bloc/Bloc'

interface ActiveProps {
  $isActive: boolean
  $isPassed: boolean
}

export interface RtbProps {
  currentIndex: number
  duration: number
  onEnd: (index: number) => void
  rtb: string[]
}

export const Rtb: React.FC<RtbProps> = ({ currentIndex, duration, onEnd, rtb, ...props }) => (
  <Container {...props}>
    <Progress>
      {rtb.map((text, index) => {
        const handleAnimationEnd = () => {
          onEnd(index)
        }

        return (
          <ProgressBar $isActive={currentIndex === index} $isPassed={index < currentIndex} key={text}>
            {currentIndex === index && <ProgressBarProgress $duration={duration} onAnimationEnd={handleAnimationEnd} />}
          </ProgressBar>
        )
      })}
    </Progress>

    <Blocs>
      {rtb.map((text, index) => (
        <Bloc isActive={currentIndex === index} isPassed={index < currentIndex} key={text}>
          {text}
        </Bloc>
      ))}
    </Blocs>
  </Container>
)

const Container = styled.div`
  padding-top: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-top: ${g(5)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-top: ${g(8)};
  }
`

const Blocs = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: flex;
    gap: ${g(5)};
  }
`

const Progress = styled.nav`
  display: flex;
  gap: ${g(1)};
  justify-content: center;
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    gap: ${g(5)};
  }
`

const loadingAnimation = keyframes`
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
`

const ProgressBar = styled.span<ActiveProps>`
  background-color: ${({ theme }) => theme.colors.neutral3};
  display: block;
  height: 1px;
  flex-basis: ${g(4)};
  position: relative;
  ${transition({ propertyName: 'background-color' })};

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: currentColor;
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-basis: ${100 / 3}%;
    transition: none;

    ${({ $isActive }) =>
      $isActive &&
      css`
        background-color: ${({ theme }) => theme.colors.neutral3};
      `}

    ${({ $isPassed }) =>
      $isPassed &&
      css`
        background-color: currentColor;
      `}
  }
`

interface ProgressBarProgressProps {
  $duration: number
}

const ProgressBarProgress = styled.span<ProgressBarProgressProps>`
  @media screen and (min-width: ${breakpoints.tablet}) {
    animation: ${loadingAnimation} ${({ $duration }) => `${$duration}s`} linear both;
    background-color: ${({ theme }) => theme.colors.neutral1};
    display: block;
    height: inherit;
    left: 0;
    position: absolute;
    top: 0;
    transform-origin: left top;
    width: 100%;
  }
`

const Bloc = styled(BlocComponent)`
  grid-column: 1;
  grid-row: 1;

  @media screen and (min-width: ${breakpoints.laptop}) {
    flex-basis: ${100 / 3}%;
  }
`
