import { breakpoints, g } from '@pretto/bricks/components/layout'

import { PrimaryButton } from '@pretto/zen/reveal/atoms/buttons/primaryButton/PrimaryButton'

import nl2br from 'react-nl2br'
import styled from 'styled-components'

export interface ContentProps {
  ctaLabel: string
  ctaUrl: string
  description: string
  isCtaDisplayed: boolean
  suptitle: string
  title: string
}

export const Content: React.FC<ContentProps & React.HTMLAttributes<HTMLDivElement>> = ({
  ctaLabel,
  ctaUrl,
  description,
  isCtaDisplayed,
  suptitle,
  title,
  ...props
}) => (
  <div {...props}>
    <Catchphrase>{suptitle}</Catchphrase>

    <Title>{title}</Title>

    <Description>{nl2br(description)}</Description>

    {isCtaDisplayed && ctaLabel && ctaUrl && <PrimaryButton href={ctaUrl}>{ctaLabel}</PrimaryButton>}
  </div>
)

const Catchphrase = styled.p`
  ${({ theme }) => theme.typos.heading6};
  margin-bottom: ${g(1)};
`

const Title = styled.h2`
  ${({ theme }) => theme.typos.heading4};
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ theme }) => theme.typos.heading3};
  }
`

const Description = styled.p`
  margin-bottom: ${g(4)};
  ${({ theme }) => theme.typos.body4};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ theme }) => theme.typos.body4};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: ${g(6, 2)};
  }
`
